<template>
    <div id="forgot" class="kt-grid kt-grid--ver kt-grid--root">
        <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                <!--begin::Aside-->
                <app-welcome />
                <!--begin::Aside-->

                <!--begin::Content-->
                <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
                    <!--begin::Body-->
                    <div class="kt-login__body">
                        <!--begin::Signin-->
                        <div class="kt-login__form">
                            <div class="kt-login__title">
                                <h3>{{ $t("user_reset") }}</h3>
                                <div class="kt-login__desc">{{ $t("user_resetDesc1") }}</div>
                                <div class="kt-login__desc">{{ $t("user_resetDesc2") }}</div>
                            </div>

                            <!--begin::Form-->
                            <form class="kt-form" action="" novalidate="novalidate" @submit.prevent="onReset">
                                <div class="form-group validated">
                                    <input class="form-control m-input" @input="$v.email.$touch()" v-model="email" id="emailInput" type="text" :placeholder="$t('user_email')" name="email" autocomplete="off" />
                                    <div class="invalid-feedback" v-if="!$v.email.required">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                    <div class="invalid-feedback" v-if="!$v.email.email">
                                        {{ $t("error_pleaseEnterValidEmail") }}
                                    </div>
                                </div>
                                <!--begin::Action-->
                                <div class="kt-login__actions">
                                    <router-link to="/">
                                        <a href="#" class="kt-link kt-login__link-forgot">
                                            {{ $t("common_cancel") }}
                                        </a>
                                    </router-link>
                                    &nbsp;&nbsp;
                                    <button id="userForgotButton" class="btn btn-primary btn-elevate kt-login__btn-primary" :disabled="$v.$invalid">
                                        {{ $t("user_reset_button") }}
                                    </button>
                                </div>
                                <!--end::Action-->
                            </form>
                            <!--end::Form-->
                        </div>
                        <!--end::Signin-->
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Content-->
            </div>
        </div>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import authHelper from "../../helpers/authHelper";

export default {
    data() {
        return {
            email: ""
        };
    },
    created: function() {
        console.log("Component(Signin)::created() - called");
    },
    mounted: function() {
        console.log("Component(Signin)::mounted() - called");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Signin)::destroyed() - called");
    },
    validations: {
        email: {
            required,
            email
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
    },
    methods: {
        ...mapActions(["reset", "showUserAlreadyLogged"]),

        onReset() {
            // User already logged in browser (another tab) signup is forbidden
            if (authHelper.isLocalStorageAuthenticated()) {
                this.showUserAlreadyLogged();
                return;
            }

            console.log("Component(Signup)::onReset() - called");
            const formData = {
                email: this.email
            };
            this.reset({ email: formData.email });
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.logo_ale {
    width: 170px;
}
</style>
